import { isMobile, setFullHeightDiff, isPhone } from "./helper"
import SiteHeader from "./siteHeader"
import MainNav from "./navigation"
import FloatLabels from "./forms/float-labels"
import MobileMenu from "./MobileMenu"
import comparisonsImagesSlider from "./comparisonImagesSlider"
import { reservationDataPicker } from "./datepicker"
import { inputFile } from "./forms/forms"
import Treatments from "./Treatments/treatments"
import Goals from "./Goals/Goals"
import preloader from "./preloader"

window.addEventListener("DOMContentLoaded", () => {

  new SiteHeader()
  new FloatLabels()
  const Menu = new MainNav()

  comparisonsImagesSlider()

  reservationDataPicker()
  inputFile('.form-file input[type="file"]')

  const mobileMenu = new MobileMenu()

  if (isMobile()) {
    Menu.addSticky()
    mobileMenu.moveToMenu(".action-bar__search-field")
  }

  if (isPhone()) {
    mobileMenu.moveToMenu(".action-bar__icons")
  }

  if (isMobile()) {
    mobileMenu.moveToMenu(".action-bar__voucher")
  }

  setFullHeightDiff("#home-hero", "#masthead")

  //treatments
  const links = document.querySelectorAll(".hierarchical-cats-list a")
  if (links.length) {
    const params = new URLSearchParams(location.search)
    const termId = params.get("termId")

    const treatments = new Treatments(links)

    if (termId) {
      treatments.getTreatmentsByTermId(termId)
    } else {
      treatments.getInitTreatments()
    }

    treatments.getTreatments()
  }

  //goal
  if (document.body.classList.contains("page-template-page-goal")) {
    const goals = new Goals()
    goals.customScroll()
    goals.setActive(".goals__main-terms input", 16)

    if (window.location.href.substring(window.location.href.lastIndexOf('/')+1).indexOf("#goals-deffects") >= 0) {
      if(typeof getCookie("lastDeffectId") !== "null") {
        //goals.setDeffects(getCookie("lastDeffectId"))
        //document.getElementById(`body-term-${getCookie("lastDeffectId")}`).click()
        //$(`#body-term-${getCookie("lastDeffectId")}`).trigger("change");
        //click(document.getElementById(`body-term-${getCookie("lastDeffectId")}`))
        //goals.getDeffects(getCookie("lastDeffectId"))
        var event = new Event('change');
        document.getElementById(`body-term-${getCookie("lastDeffectId")}`).dispatchEvent(event)

      }

    }
  }

  window.onresize = () => {
    setFullHeightDiff("#home-hero", "#masthead")

    if (isMobile()) {
      Menu.addSticky()
    }
  }

  if (document.getElementsByClassName("next")[0]) {
    document.getElementsByClassName("next")[0].innerText = "»"
  }

  if (document.getElementsByClassName("prev")[0]) {
    document.getElementsByClassName("prev")[0].innerText = "«"
  }

  //checkTreatmentsLoaded()
  if (document.body.classList.contains("post-type-archive-treatment")) {
    checkTreatmentsLoaded()
  } else {
    preloader()
  }




})

function checkTreatmentsLoaded() {
  var cont = document.getElementById("posts-container")
  var childrenClass = cont.children[0].getAttribute("class").split(" ")[0]
  if(typeof childrenClass !== 'undefined') {
    if(childrenClass !== 'loader') {
      console.log("Treatments loaded")
      preloader()
    } else {
      setTimeout(checkTreatmentsLoaded, 200)
    }
  } else {
    setTimeout(checkTreatmentsLoaded, 200)
  }
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
