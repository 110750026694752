//https://www.npmjs.com/package/smooth-scroll

import $ from "jquery"
import SmoothScroll from "smooth-scroll"




$(document).ready(function(){

  const anchor = window.location.hash
  const elem = anchor && document.querySelector(anchor)

  if ($("body").hasClass("page-template-page-price")) {
    console.log("😎 Welcome cennik!")
    const smoothScroll = new SmoothScroll('a[href*="#"]', {
      header: "#masthead",
      speed: 500,
      speedAsDuration: true,
    })
    if (elem) {
      setTimeout(() => window.scrollTo(0, 0), 1)
      setTimeout(() => smoothScroll.animateScroll(elem), 1000)
    }
  } else {
    const smoothScroll = new SmoothScroll('a[href*="#"]', {
      ignore: "[data-vc-container]",
      header: "#masthead",
      speed: 600,
    })
    if (elem) {
      setTimeout(() => window.scrollTo(0, 0), 1)
      setTimeout(() => smoothScroll.animateScroll(elem), 1000)
    }
  }



});

/*
document.addEventListener("DOMContentLoaded", () => {
  const anchor = window.location.hash
  const elem = anchor && document.querySelector(anchor)


  if (elem) {
    setTimeout(() => window.scrollTo(0, 0), 1)
    setTimeout(() => smoothScroll.animateScroll(elem), 1000)
  }
})
*/