// https://github.com/kenwheeler/slick

import $ from "jquery"
var SimpleLightbox = require('simple-lightbox');
import "slick-carousel/slick/slick"
import "slick-carousel/slick/slick.css"

// import SimpleLightBox from "simplelightbox";

var lightbox = new SimpleLightbox('.gallery a', {

});

$(".slider-container:not(#news):not(#oferta)").each(function() {
  const $this = $(this)

  $this.find(".slider:not(#offer-slider)").slick({
    appendArrows: $this.find($(".slider-dots")),
    dots: false,
    // appendDots: $this.find(".slider-dots"),
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: false,
    pauseOnFocus: false,
    speed: 1000,
    autoplaySpeed: 3500,
    prevArrow: $this.find(".slider-nav--prev"),
    nextArrow: $this.find(".slider-nav--next"),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  })
})

$(".sale-section__sales").each(function() {
  const $this = $(this)

  $this.find(".sale-section-slider").slick({
    dots: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    pauseOnFocus: false,
    speed: 1000,
    autoplaySpeed: 3500,
    prevArrow: $this.find(".slider-nav--prev"),
    nextArrow: $this.find(".slider-nav--next"),
    appendDots: $this.find(".slider-dots"),
  })
})

$(".slider-container").each(function() {
  const $this = $(this)

  $this.find("#home-effect-slider").slick({
    // dots: true,
    // appendDots: $this.find(".slider-dots"),
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    pauseOnFocus: false,
    touchMove: false,
    swipe: false,
    draggable: false,
    speed: 1000,
    autoplaySpeed: 3500,
    prevArrow: $this.find(".slider-nav--prev"),
    nextArrow: $this.find(".slider-nav--next"),
  })


})

$("#oferta").each(function() {
  const $this = $(this)

  $this.find(".slider").slick({
    dots: true,
    appendDots: $this.find(".slider-dots"),
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: true,
    pauseOnFocus: true,
    touchMove: false,
    swipe: false,
    draggable: false,
    speed: 1000,
    autoplaySpeed: 99999999,
    prevArrow: $this.find(".slider-nav--prev"),
    nextArrow: $this.find(".slider-nav--next"),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })


})

$("#news").each(function() {
  const $this = $(this)

  $this.find(".slider").slick({
    dots: false,
    appendDots: $this.find(".slider-dots"),
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: true,
    pauseOnFocus: true,
    touchMove: false,
    swipe: false,
    draggable: false,
    speed: 1000,
    autoplaySpeed: 99999999,
    prevArrow: $this.find(".slider-nav--prev"),
    nextArrow: $this.find(".slider-nav--next"),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  })


})

$(".slider-container--single").each(function() {
  const $this = $(this)

  $this.find(".slider").slick({
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
    speed: 1000,
    autoplaySpeed: 3500,
    prevArrow: $this.find(".slider-nav--prev"),
    nextArrow: $this.find(".slider-nav--next"),
    appendDots: $this.find(".slider-dots"),
  })
})

$(".slider-container--gallery")
  .each(function() {
    const $this = $(this)

    $this.find(".slider").slick({
      dots: false,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 1000,
      autoplaySpeed: 3500,
      pauseOnFocus: false,
      pauseOnHover: false,
      variableWidth: true,
      centerMode: true,
      initialSlide: 3,
      prevArrow: $this.find(".slider-nav--prev"),
      nextArrow: $this.find(".slider-nav--next"),
    })
  })
  .on("beforeChange", function(event, slick, currentSlide, nextSlide) {
    if (nextSlide == 0) {
      const opt = false
      var cls = "slick-current slick-active" + (opt ? " slick-center" : "")

      setTimeout(function() {
        $('[data-slick-index="' + slick.$slides.length + '"]')
          .addClass(cls)
          .siblings()
          .removeClass(cls)
        for (var i = slick.options.slidesToShow - 1; i >= 0; i--) {
          $('[data-slick-index="' + i + '"]').addClass(cls)
        }
      }, 10)
    }
  })
