import { getScreenHeight, isMobile } from "./helper.js"
import { overlay } from "./helper"

class MobileMenu {
  constructor() {
    this.toggledButton = document.getElementById("toggle")
    this.container = document.getElementById("site-navigation")
    this.siteHeader = document.getElementById("masthead")
    this.init()
  }

  init() {
    if (isMobile()) this.createLinks()
    this.toggleMenu()
  }

  createLinks() {
    const links = document.querySelectorAll(
      ".main-navigation li.menu-item-has-children > a",
    )

    for (const link of links) {
      const ancestor = link.closest("li")
      const subMenu = ancestor.querySelector("ul")

      const prevMenuLI = document.createElement("li")
      prevMenuLI.innerHTML = `<a class="back-to-parent">${link.textContent}</a>`

      prevMenuLI.classList.add("prev-menu")
      if (subMenu) {
        const li = document.createElement("li")
        li.innerHTML = `<a href=${link.getAttribute("href")}> ${
          link.textContent
        } </a>`
        subMenu.prepend(li)
        subMenu.prepend(prevMenuLI)
        this.previousLink(subMenu)
      }

      link.addEventListener("click", e => {
        e.preventDefault()
        subMenu.classList.toggle("active")
      })
    }
  }

  toggleMenu() {
    const html = document.querySelector("html")

    if (this.toggledButton && this.container) {
      this.toggledButton.addEventListener("click", () => {
        overlay(() => {
          this.toggledButton.classList.remove("open")
          this.container.classList.remove("open")
        }, this.siteHeader)

        if (this.toggledButton.classList.contains("open")) {
          this.toggledButton.classList.remove("open")
          this.container.classList.remove("open")
          html.style.overflowY = "auto"
          return
        }

        this.container.classList.add("open")
        this.toggledButton.classList.add("open")

        if (getScreenHeight() <= 300) {
          html.style.overflowY = "hidden"
        } else {
          html.style.overflowY = "auto"
        }
      })
    }
  }

  previousLink(ul) {
    const backToParentLink = ul.querySelectorAll(".back-to-parent")

    for (const prevBtn of backToParentLink) {
      prevBtn.addEventListener("click", () => {
        prevBtn.closest("ul").classList.remove("active")
      })
    }
  }

  moveToMenu(elem) {
    const _elem = document.querySelector(elem)

    if (_elem) {
      const cloneElem = _elem.cloneNode(true)
      this.siteHeader.querySelector(".menu").appendChild(cloneElem)
      _elem.remove()
    }
  }
}

export default MobileMenu
