import SimpleBar from "simplebar"
import SmoothScroll from "smooth-scroll"
import "simplebar/dist/simplebar.min.css"
import { callAjax, createLoader } from "../helper"

class Goals {
  constructor() {
    this.deffectsContainer = document.getElementById("goals-deffects")
    this.bodyInputs = document.querySelectorAll(".goals__body-terms input")
    this.catContainer = document.getElementById("deffects-cat-container")
    this.postsContainer = document.getElementById("deffects-posts-container")
    this.emptyGoalTemplate = document.getElementById("empty-goal")
    this.init()
  }

  init() {
    this.toggleBody()
    this.toggleDeffects()
    this.postsContainer.appendChild(
      this.emptyGoalTemplate.content.cloneNode(true),
    )
    window.addEventListener("activeGoal", ({ detail }) => {
      if (detail.name == "main-term")
        document
          .querySelector(`.goals__body[data-sex-term='${detail.termId}']`)
          .classList.add("is-active")
    })
  }

  customScroll() {
    const customScroll = document.querySelector(".custom-scrollbar")
    customScroll && new SimpleBar(customScroll)
  }

  toggleBody() {
    document.querySelectorAll(".goals__main-terms input").forEach(input =>
      input.addEventListener("change", () => {
        document
          .querySelectorAll(`.goals__body`)
          .forEach(el => el.classList.remove("is-active"))
        document
          .querySelector(`.goals__body[data-sex-term='${input.value}']`)
          .classList.add("is-active")
        this.bodyInputs.forEach(input => (input.checked = false))
        this.clearPosts()
      }),
    )
  }
  toggleDeffects() {
    const smoothScroll = new SmoothScroll()

    this.bodyInputs.forEach(input =>
      input.addEventListener("change", () => {
        this.setDeffects(input.value)
        smoothScroll.animateScroll(this.deffectsContainer, input, {
          header: "#masthead",
          offset: () => 30,
        })
      }),
    )
  }

  setActive(elements, termId) {
    const buttons = document.querySelectorAll(elements)

    if (buttons && termId) {
      const button = [...buttons].find(el => el.getAttribute("value") == termId)
      if (button) {
        const event = new CustomEvent("activeGoal", {
          detail: {
            termId,
            name: button.getAttribute("name"),
          },
        })
        window.dispatchEvent(event)
        button.checked = true
      } else console.warn(`Input with value: ${termId} not exist`)
    }
  }

  async getDeffects(termId) {
    const formData = new FormData()
    formData.append("action", "ajax_get_deffects")
    formData.append("term_id", termId)

    return await callAjax({
      method: "POST",
      body: formData,
    }).then(res => res.json())
  }

  async setDeffects(termId) {
    const loader = createLoader(this.deffectsContainer)
    setCookie("lastDeffectId", termId, 1);
    await this.getDeffects(termId).then(({ category, posts }) => {
      this.catContainer.innerHTML = category
      this.postsContainer.innerHTML = posts
      loader.remove()
    })
  }

  clearPosts() {
    this.catContainer.innerHTML = ""
    this.postsContainer.innerHTML = ""
    this.postsContainer.appendChild(
      this.emptyGoalTemplate.content.cloneNode(true),
    )
  }
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export default Goals
