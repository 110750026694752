import flatpickr from "flatpickr"
import "flatpickr/dist/themes/light.css"
import { Polish } from "flatpickr/dist/l10n/pl"

export const reservationDataPicker = () => {
  flatpickr("#your-date", {
    enableTime: true,
    dateFormat: "d-M-Y H:i",
    locale: Polish,
    time_24hr: true,
    minDate: new Date(jsData.today * 1000),
    disable: [date => date.getDay() === 0],
    // onValueUpdate: function (obj, updatedTime) {
    //   if ((updatedTime > "03:00") && (updatedTime < "15:00")) {
    //     instanceOfPickr.setDate(new Date("June 10, 2019 15:00:00"));
    //   }
    // },
  })
}
