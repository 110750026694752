import { callAjax, createLoader } from "../helper"
import $ from "jquery"

export const ajax = async formData => {
  const container = document.getElementById("posts-container")
  const loader = createLoader(container)

  return callAjax({
    method: "POST",
    body: formData,
  })
    .then(res => res.json())
    .then(res => {
      const cat = typeof res.category != "undefined" ? res.category : ""
      container.innerHTML = cat + res.posts

      if(res.relatedPosts !== 'undefined') {
          $("#container-fill-me").html(res.relatedPosts);
          console.log("Like a boss 😎");
      }

      loader.remove()
    })
}
