import SmoothScroll from "smooth-scroll"
import { ajax } from "./ajax"
import $ from "jquery"

export default class Treatments {
  constructor(links) {
    this._links = links
    this.smoothScroll = new SmoothScroll()
    this.scroll()
  }

  addAtiveClass(link) {
    document
      .querySelectorAll(".hierarchical-cats-list li")
      .forEach(el =>
        el.classList.remove("hierarchical-cats-list__ancestor--is-open"),
      )

    const ancestor = link.closest(".hierarchical-cats-list__ancestor")
    if (ancestor)
      ancestor.classList.add("hierarchical-cats-list__ancestor--is-open")

    link
      .closest("li")
      .classList.add("hierarchical-cats-list__ancestor--is-open")
  }

  scroll() {
    if (new URLSearchParams(window.location.search).get("termId")) {
      console.log("Scroll")
      this.smoothScroll.animateScroll(
        document.getElementById("primary"),
        parent,
        {
          header: "#masthead",
          offset: () => 30,
        },
      )
    }
  }

  createFormData(link) {
    const formData = new FormData()
    formData.append("action", "ajax_get_treatments")
    formData.append("term_id", link.getAttribute("data-term-id"))

    return formData
  }

  getTreatmentsByTermId(termID) {
    const formData = new FormData()

    const term = document.querySelector(`a[data-term-id="${termID}"]`)

    if (term) {
      formData.append("action", "ajax_get_treatments")
      formData.append("term_id", termID)

      this.addAtiveClass(term)
      ajax(formData)
    }
  }

  getInitTreatments() {
    const initTerm = this._links[0]
    const formData = this.createFormData(initTerm)

    this.addAtiveClass(initTerm)
    ajax(formData)
  }

  getTreatments() {
    this._links.forEach(link => {
      link.addEventListener("click", e => {
        e.preventDefault()

        const termId = link.getAttribute("data-term-id")

        const newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?termId=${termId}`
        window.history.pushState(
          {
            path: newurl,
          },
          "",
          newurl,
        )

        this.scroll()

        const formData = this.createFormData(link)
        this.addAtiveClass(link)

        ajax(formData)
      })
    })
  }
}
