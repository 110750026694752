export const inputFile = inputs => {
  const _inputs = document.querySelectorAll(inputs)

  if (_inputs.length) {
    _inputs.forEach(input =>
      input.addEventListener("change", () => {
        const name = input.files[0].name

        input.closest("div").querySelector(".file-response").innerHTML = name
      }),
    )
  }
}
